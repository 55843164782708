<template>
  <div v-if="getUserDetails" class="sub-payment">
    <div class="sub-payment-header" style="color: white">
      <div class="back">
        <img src="@/assets/svg/v2/arrowToLeft.svg" alt="Back" />
        <button @click="back()">{{ $t('commons.back') }}</button>
      </div>
    </div>

    <div class="sub-payment-body">
      <div class="payment-informations">
        <div
          v-if="getUserDetails?.address.account_type === 0"
          class="contact-billing"
        >
          <div class="contact">
            <h3>{{ $t('subscriptions.contact_informations') }}</h3>
            <p>
              +{{ getUserDetails?.address.phone_country_code }}
              {{ getUserDetails?.address.phone }} -
              {{ getUserDetails?.address.email }}
            </p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
          <div class="billing">
            <h3>{{ $t('commons.delivery') }}</h3>
            <p>{{ getClientAddress() }}</p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
        </div>
        <div v-else class="contact-billing">
          <div class="contact">
            <h3>{{ $t('subscriptions.contact_informations') }}</h3>
            <p>
              +{{ getUserDetails?.address.phone_country_code }}
              {{ getUserDetails?.address.phone }} -
              {{ getUserDetails?.address.email }}
            </p>
          </div>
          <div class="billing">
            <h3>{{ $t('commons.delivery') }}</h3>
            <p>{{ getClientAddress() }}</p>
            <button @click="$router.push('/auth/account/details')">
              {{ $t('subscriptions.update') }}
            </button>
          </div>
        </div>

        <h1>{{ $t('subscriptions.payment_method') }}</h1>

        <div class="subtitle">
          <img
            alt="Secure"
            src="@/assets/svg/v2/registerProcess/mangopaySecure.svg"
          />
          <p>{{ $t('subscriptions.payment_method_description') }}</p>
        </div>

        <div class="payment">
          <div class="payment-sources">
            <div @click="cartOrder.paymentMode = 2" class="payment-sources-selection">
              <img
                v-if="cartOrder.paymentMode < 7"
                src="@/assets/svg/v2/dotActive.svg"
                alt="Selected banking card"
              />
              <img
                v-else
                src="@/assets/svg/v2/dot.svg"
                alt="Inactive card"
              />
              <p>{{ $t('subscriptions.banking_card') }}</p>
            </div>
            <div class="payment-sources-accepted">
              <img
                v-if="card.card_type === 'visa_mastercard' || !card.card_type"
                src="@/assets/svg/v2/subscriptions/visa.svg"
                alt="Visa"
              />
              <img
                v-if="card.card_type === 'visa_mastercard' || !card.card_type"
                src="@/assets/svg/v2/subscriptions/mastercard.svg"
                alt="Mastercard"
              />
              <img
                v-if="card.card_type === 'amex' || !card.card_type"
                src="@/assets/svg/v2/subscriptions/ae.svg"
                alt="AE"
              />
            </div>
          </div>

          <form>
            <el-input
              class="form-input"
              :class="{ errorInput: cardRegex.card_number }"
              :placeholder="$t('subscriptions.card_number')"
              type="text"
              minlength="15"
              maxlength="20"
              v-model="card.card_number"
              :size="size"
              :suffix-icon="Lock"
            />
            <el-input
              class="form-input"
              :class="{ errorInput: cardRegex.fullnames }"
              :placeholder="$t('subscriptions.card_fullnames')"
              type="text"
              minlength="5"
              maxlength="75"
              v-model="card.fullnames"
              :size="size"
            />

            <div>
              <el-input
                class="form-input"
                clearable
                :class="{ errorInput: cardRegex.card_expiration_date }"
                :placeholder="$t('subscriptions.card_expiration')"
                type="text"
                minlength="5"
                maxlength="10"
                v-model="card.card_expiration_date"
                :size="size"
              />
              <el-input
                class="form-input"
                :class="{ errorInput: cardRegex.card_cvx }"
                :placeholder="$t('subscriptions.card_code_security')"
                type="text"
                minlength="3"
                maxlength="4"
                v-model="card.card_cvx"
                :size="size"
                :suffix-icon="QuestionFilled"
              />
            </div>
          </form>
        </div>

        <!-- <div class="payment">
          <div class="payment-sources">
            <div @click="cartOrder.paymentMode = 8" class="payment-sources-selection">
              <img
                v-if="cartOrder.paymentMode === 8"
                src="@/assets/svg/v2/dotActive.svg"
                alt="Selected paypal payment method"
              />
              <img
                v-else
                src="@/assets/svg/v2/dot.svg"
                alt="Inactive paypal"
              />
              <p>Paypal</p>
            </div>
            <div class="payment-sources-accepted">
              <img
                src="@/assets/svg/v2/payment/paypal.svg"
                alt="Paypal"
              />
            </div>
          </div>
        </div> -->

        <div v-if="(wallet?.available_amount - totalWithPaymentMethod) > 0" class="payment">
          <div class="payment-sources">
            <div @click="cartOrder.paymentMode = 7" class="payment-sources-selection">
              <img
                v-if="cartOrder.paymentMode === 7"
                src="@/assets/svg/v2/dotActive.svg"
                alt="Selected wallet payment method"
              />
              <img
                v-else
                src="@/assets/svg/v2/dot.svg"
                alt="Inactive wallet"
              />
              <p>{{ $t('commons.wallet') }}</p>
            </div>
            <div class="payment-sources-accepted">
              <img
                src="@/assets/svg/v2/wallet/wallet.svg"
                alt="Wallet"
              />
            </div>
          </div>

          <form>
            <h5>{{ $t('wallet.available_amount') }} : {{ wallet?.available_amount }} EUR</h5>
            <h5 v-if="cartOrder.paymentMode === 7">{{ $t('wallet.available_after_transaction') }} {{ parseFloat(wallet?.available_amount - totalWithPaymentMethod).toFixed(2) }} EUR</h5>
          </form>
        </div>

      </div>
      <div class="cart">
        <div v-if="uniqueOrder" class="cart-drawer-content">
          <h3 class="premium">{{ $t('subscriptions.recap_payment') }}</h3>

          <div class="cart-drawer-subscriptions">
            <div class="order-payment-informations">
              <h3>{{ cartOrder.title }}</h3>
              <p>{{ uniqueOrder.price }} EUR</p>
            </div>
            <div class="order-payment-informations">
              <h3>{{ $t('payment_process.shipping_ups') }}</h3>
              <p>{{ uniqueOrder.package_price }} EUR</p>
            </div>
            <div class="order-payment-informations">
              <h3>{{ $t('payment_process.inssurance_price') }}</h3>
              <p>{{ uniqueOrder.insurance_price }} EUR</p>
            </div>
            <div class="order-payment-informations">
              <h3>{{ $t('subscriptions.buy_commission') }} (2.08%)</h3>
              <p>{{ uniqueOrder.fees }} EUR</p>
            </div>
            <div v-if="cartOrder.paymentMode !== 7" class="order-payment-informations">
              <h3>
                {{ $t('payment_process.payment_type_fees') }}
                <span v-if="getCardType">({{ selectedCardType.fees }}%)</span>
                <span v-else>(- %)</span>
              </h3>
              <p v-if="getCardType">{{ getCardType }} EUR</p>
              <p v-else>0 EUR</p>
            </div>
            <div class="order-payment-informations">
              <h3>{{ $t('subscriptions.buy_commission_tva') }} (20%)</h3>
              <p>{{ uniqueOrder.fees_vat }} EUR</p>
            </div>
          </div>

          <el-divider />

          <div class="cart-drawer-total">
            <div>
              <h4>{{ $t('subscriptions.total') }}</h4>
            </div>
            <h3 style="color: white">{{ totalWithPaymentMethod }} EUR</h3>
          </div>

          <button v-if="cartOrder.paymentMode" class="generic-btn" @click="pay()">
            {{ $t('subscriptions.pay') }}
          </button>
        </div>

        <div class="mangopay">
          <img alt="Mangopay" src="@/assets/svg/mangopayGrey.svg" />
        </div>
      </div>
    </div>

    <payment3DSecureVue v-if="get3DSecureDialog" @end="endTransaction()" />
  </div>
</template>

<script setup>
import { Lock, QuestionFilled } from '@element-plus/icons-vue'
</script>

<script>
import { mapActions, mapGetters } from 'vuex'
import { ElMessage, ElLoading } from 'element-plus'
import { find } from 'lodash'
import payment3DSecureVue from '@/components/modules/payments/payment3DSecure.vue'

export default {
  components: {
    payment3DSecureVue,
  },
  data() {
    return {
      size: 'large',

      cart: [],
      cartOrder: [],

      card: {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: 'visa_mastercard',
      },
      cardRegex: {
        fullnames: false,
        card_number: false,
        card_expiration_date: false,
        card_cvx: false,
      },
      cardType: [
        { type: 'visa_mastercard', prfx: ['4'], fees: 2 },
        {
          type: 'visa_mastercard',
          prfx: ['51', '52', '53', '54', '55'],
          fees: 2,
        },
        { type: 'amex', prfx: ['34', '37'], fees: 3 },
      ],
      selectedCardType: null,
    }
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
      getOrder: 'getOrder',
      get3DSecureDialog: 'get3DSecureDialog',
      wallet: 'getWallet',
      getLocationSelected: 'getLocationSelected',
    }),
    totalWithPaymentMethod() {
      let paymentFees = 0
      if (this.uniqueOrder) {
        if (this.card.card_type === 'visa_mastercard' && this.selectedCardType && this.cartOrder.paymentMode < 7) {
          paymentFees = this.uniqueOrder.total + this.uniqueOrder.payment_type_fees.visa_mastercard
        } else if (this.card.card_type === 'amex' && this.selectedCardType && this.cartOrder.paymentMode < 7) {
          paymentFees = this.uniqueOrder.total + this.uniqueOrder.payment_type_fees.amex
        } else if (this.cartOrder.paymentMode === 8) {
          paymentFees = this.uniqueOrder.total + this.uniqueOrder.payment_type_fees.paypal
        } else {
          paymentFees = this.uniqueOrder.total
        }
      }

      return parseFloat(paymentFees).toFixed(2)
    },
    uniqueOrder() {
      return find(this.getOrder, { uuid: this.cartOrder.uuid })
    },
    getCardType() {
      let card = this.card.card_number
      const carteNumbers = card.replace(/\D/g, '')
      
      if (this.cartOrder.paymentMode === 8) {
        this.selectedCardType = {
          fees: 1
        }
        return this.uniqueOrder.payment_type_fees.paypal
      } else {
        for (let i = 0; i < this.cardType.length; i++) {
          for (const prf of this.cardType[i].prfx) {
            if (carteNumbers.startsWith(prf)) {
              if (this.cardType[i].type === 'visa_mastercard') {
                this.card.card_type = 'visa_mastercard'
                this.selectedCardType = this.cardType[i]
                return this.uniqueOrder.payment_type_fees.visa_mastercard
              } else if (this.cardType[i].type === 'amex') {
                this.card.card_type = 'amex'
                this.selectedCardType = this.cardType[i]
                return this.uniqueOrder.payment_type_fees.amex
              }
              this.card.card_type = null
              return 0
            } else if (!card) {
              return 0
            }
          }
        }
      }

      this.card.card_type = null
      return 0
    },
  },
  watch: {
    'card.card_expiration_date': function () {
      if (this.card.card_expiration_date) {
        let slashCount = 0
        for (var i = 0; i < this.card.card_expiration_date.length; i++) {
          if (this.card.card_expiration_date[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.card.card_expiration_date = ''
        }

        if (this.card.card_expiration_date.length === 2) {
          this.card.card_expiration_date += '/'
        }
      }
    }
  },
  mounted() {
    if (!this.getUserDetails) {
      this.getClient()
    }
    const save = localStorage.getItem('orderCart')
    this.cartOrder = JSON.parse(save);

    if (this.getOrder.length === 0) {
      this.$router.push('/search-products');
    }
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      orderPayment: 'orderPayment',
    }),
    dynamicPath(path) {
      return require(`@/assets/svg/v2/categories/${path}`)
    },
    back() {
      this.$router.go(-1)
    },
    async pay() {
      if (this.cartOrder.paymentMode < 7) {
        
        const regex_card_number = /^(4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13})$/
        const regex_card_fullnames = /^([a-zA-ZÀ-ÖØ-öø-ÿ'’-]+(?:\s+[a-zA-ZÀ-ÖØ-öø-ÿ'’-]+)*)$/
        const regex_card_expiration = /^(0[1-9]|1[0-2])\/([0-9]{2})$/
        const regex_card_cvx = /^[0-9]{3,4}$/
  
        if (!regex_card_number.test(this.card.card_number)) {
          this.cardRegex.card_number = true
          ElMessage({
            message: this.$t('register.step_3.error_card_number'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_number = false
        }
  
        if (!regex_card_expiration.test(this.card.card_expiration_date)) {
          this.cardRegex.card_expiration_date = true
          ElMessage({
            message: this.$t('register.step_3.error_card_expiration_date'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_expiration_date = false
        }
  
        if (
          !regex_card_fullnames.test(this.card.fullnames) ||
          this.card.fullnames.length < 1
        ) {
          this.cardRegex.fullnames = true
          ElMessage({
            message: this.$t('register.step_3.error_card_fullnames'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.fullnames = false
        }
  
        if (!regex_card_cvx.test(this.card.card_cvx)) {
          this.cardRegex.card_cvx = true
          ElMessage({
            message: this.$t('register.step_3.error_card_cvx'),
            type: 'warning',
            grouping: true,
          })
        } else {
          this.cardRegex.card_cvx = false
        }
  
        if (
          !this.cardRegex.card_number &&
          !this.cardRegex.card_expiration_date &&
          !this.cardRegex.card_cvx &&
          !this.cardRegex.fullnames
        ) {
          const payload = {
            card: {
              fullnames: this.card.fullnames,
              card_number: this.card.card_number,
              card_expiration_date: this.card.card_expiration_date,
              card_cvx: this.card.card_cvx,
              card_type: this.card.card_type,
            },
            order: {
              order_uuid: this.uniqueOrder.uuid,
            },
            shipment: {
              type: this.getLocationSelected ? 1 : 0
            }
          }
  
          payload.browserInfos = {
            AcceptHeader: 'text/html, application/xhtml+xml, application/xml;q=0.9, /;q=0.8',
            JavaEnabled: navigator.javaEnabled(),
            Language: navigator.language,
            ColorDepth: window.screen.colorDepth,
            ScreenHeight: window.screen.height,
            ScreenWidth: window.screen.width,
            TimeZoneOffset: new Date().getTimezoneOffset(),
            UserAgent: navigator.userAgent,
            JavascriptEnabled: true,
          }
  
          const loading = ElLoading.service({
            lock: true,
            background: 'rgba(0, 0, 0, 0.7)',
          })
  
          await this.orderPayment(payload)
            .then(() => {
              this.$router.push('/auth/transactions/history')
              loading.close()
            })
            .catch(() => {
              loading.close()
            })
        }

      } else if (this.cartOrder.paymentMode === 7) {

        this.cardRegex = {
          fullnames: false,
          card_number: false,
          card_expiration_date: false,
          card_cvx: false,
        }

        const payload = {
          wallet: true,
          order: {
            order_uuid: this.uniqueOrder.uuid,
          },
          shipment: {
            type: this.getLocationSelected ? 1 : 0
          }
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.orderPayment(payload)
        .then(() => {
          this.$router.push('/auth/transactions/history')
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
      } else if (this.cartOrder.paymentMode === 8) {
        this.cardRegex = {
          fullnames: false,
          card_number: false,
          card_expiration_date: false,
          card_cvx: false,
        }

        const payload = {
          paypal: true,
          order: {
            order_uuid: this.uniqueOrder.uuid,
          },
          shipment: {
            type: this.getLocationSelected ? 1 : 0
          }
        }

        const loading = ElLoading.service({
          lock: true,
          background: 'rgba(0, 0, 0, 0.7)',
        })

        await this.orderPayment(payload)
        .then(() => {
          this.$router.push('/auth/transactions/history')
          loading.close()
        })
        .catch(() => {
          loading.close()
        })
      }
    },
    getClientAddress() {
      if (this.getLocationSelected) {

        return `${this.getLocationSelected.address.name}`
        
      } else {
        const findAddress = find(
          this.getUserDetails.address.delivery_multiple_address,
          (o) => {
            return o.is_reference === true
          }
        )
        if (findAddress) {
          return `${findAddress.address}, ${findAddress.zip} ${findAddress.city}`
        } else {
          return `N/A`
        }
      }
    },
    endTransaction() {
      this.$store.commit('SET_DIALOG_3DSECURE', null)
      this.$store.commit('SET_TRANSACTION_UUID', null)
      this.$store.commit('SET_TRANSACTION_TYPE', null)
      this.$router.push('/auth/transactions/history')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.sub-payment-body,
.back,
.contact,
.billing,
.subtitle,
.payment-sources,
.payment-sources-selection,
form div {
  display: flex;
}

.sub-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 66px;
  margin: auto;
  margin-bottom: 80px;
  max-width: 1400px;
}

.sub-payment-header {
  margin-top: 48px;
  margin-bottom: 43px;
  text-align: start;
  width: 100%;

  .back {
    margin-bottom: 23px;

    button {
      background: transparent;
      border: 0;
      color: $subtitle;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.sub-payment-body {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .payment-informations {
    margin-right: 100px;

    .back {
      margin-bottom: 23px;

      button {
        background: transparent;
        border: 0;
        color: $subtitle;
        font-size: 15px;
        font-weight: 500;
      }
    }

    .contact-billing {
      border-radius: 12px;
      border: 1px solid $border;
      background: #222;
      margin-bottom: 47px;

      .contact,
      .billing {
        padding: 20px 17px;
        padding-right: 7px;

        h3 {
          color: $subtitle;
          font-size: 16px;
          font-weight: 500;
          min-width: 180px;
        }

        p {
          color: white;
          font-size: 16px;
          font-weight: 500;
          min-width: 400px;
        }

        button {
          background: transparent;
          border: 0;
          color: #add7f6;
          font-size: 15px;
          font-weight: 600;
          margin-left: 8px;
        }
      }

      .contact {
        border-bottom: 1px solid $border;
      }
    }

    h1 {
      margin-bottom: 16px;
    }

    .subtitle {
      align-items: start;
      margin-bottom: 35px;

      img {
        margin-right: 8px;
      }

      p {
        font-size: 15px;
        font-weight: 500;
      }
    }

    .payment {
      border-radius: 12px;
      border: 1px solid $border;
      background: #222;
      overflow: hidden;
      margin-bottom: 10px;

      .payment-sources {
        align-items: center;
        justify-content: space-between;
        padding: 17px;

        .payment-sources-selection {
          align-items: center;
          cursor: pointer;

          p {
            color: white;
            font-size: 16px;
            font-weight: 500;
            margin-left: 8px;
          }
        }

        .payment-sources-accepted {
          display: flex;
          justify-content: flex-end;
          min-width: 130px;

          img {
            margin-left: 8px;
          }
        }
      }

      form {
        flex-direction: column;
        padding: 23px;
        background: #333;
        border-top: 1px solid $border;

        .form-input {
          border-radius: 5px;
          border: 1px solid $border;
          background: #333;
          margin-bottom: 16px;
          padding-left: 15px;
        }

        div {
          .form-input:nth-child(1) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.mangopay {
  display: flex;
  justify-content: center;
  margin-top: 29px;
}

.cart-drawer-content {
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  min-width: 444px;
  padding: 24px 17px;

  .premium {
    font-family: Hyperwave;
    color: white;
    font-size: 22px;
    font-weight: 700;
  }

  .cart-drawer-subscriptions {
    margin-top: 36px;

    .order-payment-informations {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      margin-bottom: 15px;

      h3 {
        font-size: 17px;
        font-weight: 500;
      }

      p {
        text-align: right;
        margin-left: 20px;
        min-width: 90px;
      }
    }
  }

  .cart-drawer-subscriptions-infos {
    div {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      h4 {
        color: $subtitle;
        font-size: 16px;
        font-weight: 500;
      }

      p {
        color: white;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }

  .cart-drawer-total {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 42px;

    div {
      h4 {
        color: $subtitle;
        font-size: 20px;
        font-weight: 500;
      }

      p {
        color: $grey40;
        font-size: 14px;
        font-weight: 500;
      }
    }

    p {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }
  }

  button {
    max-width: 412px;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .sub-payment {
    padding: 0px;
    margin-bottom: 15px;

    .sub-payment-header {
      margin-top: 20px;
      margin-bottom: 10px;
      padding-left: 10px;
    }

    .payment-informations {
      display: flex;
      margin-right: 0;
      flex-direction: column;
      align-items: center;

      .payment {
        border-radius: 0;
        border-left: 0;
        border-right: 0;
      }

      .subtitle {
        margin-left: 10px;
      }

      .contact-billing {
        margin-bottom: 25px;
        border-left: 0;
        border-right: 0;
        border-radius: 0;

        .contact,
        .billing {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          width: 350px;

          h3,
          p {
            width: auto;
            min-width: auto !important;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
          }

          button {
            max-width: 65px;
            margin-left: 0;
          }
        }
      }
    }

    .sub-payment-body {
      flex-wrap: wrap;

      .cart {
        margin: auto;
        margin-top: 20px;
        width: 100%;

        .cart-drawer-content {
          min-width: auto;
          border-radius: 0;
          border-left: 0;
          border-right: 0;
        }

        .generic-btn {
          width: 200px;
        }
      }
    }
  }
}
</style>
